/* eslint-disable react/display-name */
import { memo, useState, useEffect } from 'react';
import useTranslation from 'next-translate/useTranslation';

import Link from 'next/link';

import { useRouter } from 'next/router';

import { RoutePath } from 'src/constants/route-path';
import { H1, H2, Caption, A, ButtonTextBig } from 'src/components/Text';
import ErrorNotification from 'src/components/ErrorNotification';

import { getCookie, setCookie } from 'src/utils/cookie';

import { StepProps } from '../../models';

import {
  StepYourBodyWellWrapper,
  StepYourBodyWellChoices,
  StepYourBodyWellItem,
  GenderImg,
  GenderBtnContainer,
  GenderBtn,
  PrivacyCheckbox,
  PrivacyWrapper,
  EmailConfirmWrapper,
  EmailConfirmCheckbox,
  GenderIllustration,
} from './step-your-body-well-styled';

export const StepYourBodyWell = memo(
  ({ data, onSubmit, showEmailCheckbox }: StepProps): JSX.Element => {
    const router = useRouter();
    const { t } = useTranslation('quiz');

    const [privacyChecked, togglePrivacy] = useState(true);
    const [emailChecked, toggleEmail] = useState(true);

    // const [isUtm, setIsUtm] = useState(false);
    // const [isPP, setIsPP] = useState(false);

    useEffect(() => {
      setCookie('wantReceiveMarketingEmails', emailChecked ? '1' : '0');
    }, [emailChecked]);

    useEffect(() => {
      if (Object.values(router.query).length <= 2) {
        toggleEmail(false);
        togglePrivacy(false);
      }
    }, []);

    return (
      <>
        <StepYourBodyWellWrapper>
          <H1>{data.title}</H1>
          <H2>{data.subtitle}</H2>
          <StepYourBodyWellChoices>
            {data.nav.map((nav): JSX.Element[] =>
              data.selects[nav.id].options.map((option): JSX.Element => {
                const illustration = `/images/yourbodywell/gender-${option.analyticId}.png`;

                return (
                  <StepYourBodyWellItem key={option.id}>
                    <GenderIllustration>
                      <img src={illustration} alt="" />
                    </GenderIllustration>
                    <GenderBtn
                      disabled={!privacyChecked}
                      type="button"
                      onClick={
                        privacyChecked
                          ? (): void =>
                              onSubmit({
                                formValue: { [option.id]: option.value },
                              })
                          : undefined
                      }
                    >
                      <GenderBtnContainer>
                        <GenderImg>
                          <img
                            src={option.icon}
                            alt={option.title}
                            loading="lazy"
                          />
                        </GenderImg>
                        <ButtonTextBig>{option.title}</ButtonTextBig>
                      </GenderBtnContainer>
                    </GenderBtn>
                  </StepYourBodyWellItem>
                );
              })
            )}
          </StepYourBodyWellChoices>
          <PrivacyWrapper>
            <PrivacyCheckbox
              className={privacyChecked ? 'checked' : ''}
              role="button"
              onClick={(): void => {
                togglePrivacy(!privacyChecked);
              }}
            />
            <Caption>
              {t('goal.agreement')}{' '}
              <Link
                href={`${RoutePath.termsOfService}`}
                passHref
                prefetch={false}
              >
                <A>{t('goal.terms')}</A>
              </Link>
              ,{' '}
              <Link
                href={`${RoutePath.privacyPolicy}`}
                passHref
                prefetch={false}
              >
                <A>{t('goal.privacy')}</A>
              </Link>
              ,{' '}
              <Link
                href={`${RoutePath.subscriptionTerms}`}
                passHref
                prefetch={false}
              >
                <A>{t('goal.subscription')}</A>
              </Link>
              {', '}
              <Link
                href={`${RoutePath.cookiePolicy}`}
                passHref
                prefetch={false}
              >
                <A>{t('goal.cookiePolicy')}</A>
              </Link>
            </Caption>
          </PrivacyWrapper>
          {showEmailCheckbox && (
            <EmailConfirmWrapper>
              <EmailConfirmCheckbox
                className={emailChecked ? 'checked' : ''}
                role="button"
                onClick={(): void => {
                  toggleEmail(!emailChecked);
                }}
              />
              <Caption>
                {t('goal.email')}
                {process.env.NEXT_PUBLIC_MIRROR_TITLE || 'Unimeal'}{' '}
                {t('goal.emailEnd')}
              </Caption>
            </EmailConfirmWrapper>
          )}
          {!privacyChecked && (
            <ErrorNotification>{t('goal.error')}</ErrorNotification>
          )}
        </StepYourBodyWellWrapper>
      </>
    );
  }
);

export default StepYourBodyWell;
