import styled, { css } from 'styled-components';

import { H1, H2, ButtonTextBig } from 'src/components/Text';
import {
  smMedia,
  smlMedia,
  mdMedia,
  themeColor,
  themeImages,
} from 'src/theme/utils';

export const StepYourBodyWellWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 940px;
  margin: 0 auto;
  ${H1} {
    margin-bottom: 24px;
  }
  ${H2} {
    margin-bottom: 16px;
  }
  ${smMedia(css`
    ${H1} {
      text-align: center;
      margin-bottom: 24px;
    }
    ${H2} {
      text-align: center;
      margin-bottom: 0;
    }
  `)}

  ${mdMedia(css`
    ${H1} {
      text-align: center;
      margin-bottom: 40px;
    }
    ${H2} {
      text-align: center;
      margin-bottom: 0;
    }
  `)}
`;

export const StepYourBodyWellChoices = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  ${smMedia(css`
    justify-content: space-between;
  `)}
`;

export const StepYourBodyWellItem = styled.div`
  display: flex;
  justify-content: flex-end;
  ${mdMedia(css`
    min-height: 337px;
    &:nth-child(1) {
      flex-direction: row;
      width: calc(50% - 12px);
      margin-right: 12px;
    }
    &:nth-child(2) {
      flex-direction: row-reverse;
      width: calc(50% - 12px);
      margin-left: 12px;
    }
  `)}
`;

export const GenderIllustration = styled.div`
  display: none;
  ${mdMedia(css`
    display: flex;
    position: relative;
    height: 337px;
    margin: 0 35px;
    img {
      height: 100%;
    }
  `)}
`;

export const GenderImg = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const GenderBtn = styled.button`
  position: relative;
  cursor: pointer;
  border: none;
  outline: none;
  width: 156px;
  height: 156px;
  border-radius: 78px;
  background: transparent;
  &:after {
    content: '';
    width: 156px;
    height: 156px;
    border-radius: 78px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
  &:before {
    content: '';
    width: 138px;
    height: 138px;
    border-radius: 69px;
    position: absolute;
    top: 9px;
    left: 9px;
    z-index: 2;
  }
  ${smMedia(css`
    margin-top: 24px;
  `)}
`;

export const GenderBtnContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  ${ButtonTextBig} {
    color: ${themeColor('black0')};
  }
  width: 120px;
  height: 120px;
  margin: 0 auto;
  border-radius: 60px;
  z-index: 3;
`;

export const PrivacyWrapper = styled.div`
  display: flex;
  margin: 1.5rem auto 0;
  max-width: 556px;
  ${smlMedia(css`
    margin-left: 0;
  `)}
  ${mdMedia(css`
    margin: 2.5rem auto 0;
  `)}
`;

export const PrivacyCheckbox = styled.div`
  width: 24px;
  height: 24px;
  flex: 0 0 24px;
  margin-right: 0.5rem;
  background: url(/icons/cmn_check_default.svg) no-repeat center;
  background-size: contain;
  cursor: pointer;
  &.checked {
    background: url(${themeImages('iconCheck')}) no-repeat center;
  }
`;

export const EmailConfirmWrapper = styled.div`
  display: flex;
  margin: 1rem auto 0;
  max-width: 556px;
  ${smlMedia(css`
    margin-left: 0;
  `)}
  ${mdMedia(css`
    margin: 1rem auto 0;
  `)}
`;

export const EmailConfirmCheckbox = styled.div`
  width: 24px;
  height: 24px;
  flex: 0 0 24px;
  margin-right: 0.5rem;
  background: url(/icons/cmn_check_default.svg) no-repeat center;
  background-size: contain;
  cursor: pointer;
  &.checked {
    background: url(${themeImages('iconCheck')}) no-repeat center;
  }
`;
